import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
// import { remoteConfig } from '../firebaseconf';

import loadable from '@loadable/component';
import { setLocationAndLang } from '../redux/action/user-action';

import { createBrowserHistory } from "history";
export const history = createBrowserHistory({forceRefresh:true});

// load the remaining components asynchronously with loading overlay
const AsyncHome = loadable(() => import('../components/home'));

const AsyncError = loadable(() => import('../components/layout/error'));

const AsyncPractice = loadable(() => import('../components/pratice'));

const AsynContact = loadable(() => import('../components/contact'));

const AsyncCareer = loadable(() => import('../components/career'))

const AsyncProfiles = loadable(() => import('../components/profiles'));

const AsynArticles = loadable(() => import('../components/articles'));

const Routes = (props) => {
  const dispatch = useDispatch();

  // const { defaultConfig } = remoteConfig;
  const { match, location, lang } = props;
  const { pathname } = location;

  useEffect(() => {
    dispatch(setLocationAndLang(match.url.substr(1,2), pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLocationAndLang(match.url.substr(1,2), pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Switch>
        {/* <Route exact path={this.state.root} component={() => <AsyncLogin />} /> */}

        {/* remove the trailing / */}
        <Route strict exact from={`/${lang}/`} to={`/${lang}`} />

        <Route exact path={`/${lang}/`} component={() => <AsyncHome />} />

        <Route exact path={`/${lang}/practice`} component={() => <AsyncPractice />} />

        <Route exact path={`/${lang}/profiles`} component={() => <AsyncProfiles />} />
        <Route exact path={`/${lang}/profile/:path`} component={() => <AsyncProfiles />} />

        <Route exact path={`/${lang}/career`} component={() => <AsyncCareer />} />

        {/* <Route exact path={`/${lang}/articles`} component={() => <AsyncComingSoon />} /> */}
        <Route exact path={`/${lang}/articles`} component={() => <AsynArticles />} />
        <Route exact path={`/${lang}/article/:path`} component={() => <AsynArticles />} />

        <Route exact path={`/${lang}/contact`} component={() => <AsynContact />} />

        <Route component={() => <AsyncError errorCode="404" />} />
      </Switch>
    </>
  )
}

export default (withRouter(Routes));