import { createStore, applyMiddleware, compose } from 'redux';
import persistedReducer from './reducers';

import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';

// middleware for basic Redux side effects logic, 
// including complex synchronous logic that needs access to the store, 
// and simple async logic like AJAX requests.
import thunk from 'redux-thunk';   

const middleware = [
    thunk,
];

// Middleware: Redux Logger (For Development)
if (process.env.NODE_ENV !== 'production') {  
    middleware.push(createLogger());
}

// use compose to allow us to include devtools and thunk in Redux's store
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default() => {
    // create Redux store of all of the composers, apply redux-thunk and create loger
    const store = createStore(
        persistedReducer,
        composeEnhancer(applyMiddleware(...middleware))
    );
    
    // Middleware: Redux Persist Persister
    let persistor = persistStore(store);

    return { store, persistor }
}