import React from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { IntlProvider } from "react-intl";
import Routes from "./routes-view";

import loadable from '@loadable/component';
import { supportedLanguages } from '../utils/const';

import '../static/css/style.css';
import '../static/css/skeleton.css';

import ScrollToTop from 'react-router-scroll-top';
import { createBrowserHistory } from "history";
export const history = createBrowserHistory({forceRefresh:true});

// Load the remaining components asynchronously with loading overlay
const AsyncError= loadable(() => import('../components/layout/error'));

// React Route Element Definition
const LangRoutes = () => {
  const lang = useSelector(state => state.user.config.lang);
  const transText = useSelector(state => state.user.config.transText);

  return (
    <IntlProvider locale={lang} messages={transText} onError={() => {}}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            {/* Check if the language exists */}
            {/* If exists, pass it to the next router. Else, show error page */}
            <Route
              path="/:lang"
              render={(props) => {
                return supportedLanguages.includes(props.match.params.lang) ? (
                  <Routes {...props} lang={props.match.params.lang} />
                ) : (
                  <Route component={() => <AsyncError errorCode="404" />} />
                )
              }}
            />
            {/* Use English if langage is not specified */}
            <Redirect to={"/" + lang} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default LangRoutes;