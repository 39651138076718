import {
  GET_HOME_SLIDER_IMG_REQUEST, GET_HOME_SLIDER_IMG_SUCCESS, GET_HOME_SLIDER_IMG_FAILURE,
} from "../types/home-types";

const initialDebug = {
  errorCode: '',
  message: '',
}
const initialSliderState = {};

export const initialHomeState = {
  sliderImg: initialSliderState,
  isLoading : false,
  debug: initialDebug,
}

const homeReducer = (state = initialHomeState, action) => {
  switch(action.type){
    case GET_HOME_SLIDER_IMG_REQUEST: {
      return Object.assign({}, state, {
        sliderImg: initialSliderState,
        isLoading: true,
        debug : initialDebug,
      });
    }

    case GET_HOME_SLIDER_IMG_SUCCESS: {
      return Object.assign({}, state, {
        sliderImg: action.sliderImg,
        isLoading: false,
      });
    }

    case GET_HOME_SLIDER_IMG_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        debug: Object.assign({}, state.debug, {
          errorCode : action.errorCode,
          message   : action.message
        }),
      });
    }

    default: {
      return state;
    }
  }
};

export default homeReducer;