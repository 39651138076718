import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

import home from './home';
import user from './user';

const appReducer = combineReducers({
  home,
  user,
})

const rootReducer = (state, action) => {
  return appReducer(state, action);
}

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'primary',
  // defaults to localStorage for web
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'home',
    'user',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'profile',  // FIXME: Deprecate this
  ],
  stateReconciler: autoMergeLevel2,
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default withReduxStateSync(persistedReducer);
export default persistedReducer;