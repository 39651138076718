import { SET_LOC_AND_LANG } from "../types/user-types";
import { initialDebug } from "../const";
// import { REHYDRATE } from 'redux-persist/lib/constants';

import messages_en from "../../static/translations/en.json";

const initialConfig = {
  location: '/en',
  lang: 'en',
  transText: messages_en,
}

// location -> path after ../en/ or ../id/ (i.e. /history)
const initialUserState = {
  config: initialConfig,
  debug : initialDebug,
};

const userReducer = (state = initialUserState, action) => {
  switch(action.type){
    // non-firebase related reducers
    case SET_LOC_AND_LANG:{
      return Object.assign({}, state, {
        config: Object.assign({}, state.config, {
          location  : action.location,
          lang      : action.lang,
          transText : action.transText,
        }),
      });
    }

    // case REHYDRATE : { // reset error message upon page refresh
    //     // var res;
    //     // if (action.payload)
    //     //     res = action.payload.user;
    //     // else
    //     //     res = state;

    //     // console.log(action.payload);
    //     // console.log(res);
    //     // return Object.assign({}, state, {
    //     //     lang       : res.lang,
    //     // })
    //     // return {
    //     //     message    : "",
    //     //     email      : "",
    //     //     isLoggedIn : res.isLoggedIn,
    //     //     location   : res.location,
    //     //     lang       : res.lang,
    //     //     transText  : res.transText,
    //     //     user       : res.user,
    //     // }
    // }

    default: {
      return state;
    }
  }
}

export default userReducer;