import { SET_LOC_AND_LANG } from "../types/user-types";

import messages_id from "../../static/translations/id.json";
import messages_en from "../../static/translations/en.json";

export let transText = "";

/*
 * Non firebase related functions
 */
// set the location and language
export const setLocationAndLang = (lang, location) => {
  return function (dispatch) {
    switch (lang) {
      case 'id': transText=messages_id; break;
      case 'en': transText=messages_en; break;
      default: transText=messages_en; break;
    }

    dispatch({
      type: SET_LOC_AND_LANG,
      lang,
      transText,
      location,
    });
  }
}
