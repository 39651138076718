export const rootURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:3000" : "https://samosirco.com";

export const supportedLanguages = ["en"];

export const NotFoundTitle = "Page Not Found";
export const NotFoundMsg = "Please check if you have entered the correct URL.";

export const sm = 768;
export const md = 992;
export const lg = 1200;

// feature flags
export const DEV_FEATURE_FLAG_MIN_FETCH_MIN = 1;
export const PROD_FEATURE_FLAG_MIN_FETCH_MIN = 60;

export const SHOW_ARTICLES = "show_articles";

// blog posts ID
export const LAWYER_PROFILE_EN = "3302285495756402856";
export const LAWYER_PROFILE_ID = "7634445375052808567";

export const ARTICLE_EN = "1638603116553455381";
export const ARTICLE_ID = "5006660685530620196";

export const CAREER_EN = "8980599312302643994";
export const CAREER_ID = "6674842164870145058";